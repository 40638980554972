<template>
  <div class="clue-defeat">
    <div class="totalCount">{{$t('共')}} {{ totalCount }} {{$t('条数据')}}</div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <DefeatCardItem v-if="typeInfo === 'defeat'" :item-data="item" :showCheckBox="false" @click="handlerTo(item)"/>
        <InvalidCardItem v-if="typeInfo === 'invalid'" :item-data="item" :showCheckBox="false" @click="handlerTo(item)"/>
      </template>
    </List>
  </div>
  </template>
<script>
import List from '@/components/baseList/list.vue'
import DefeatCardItem from '@/modules/clueDefeat/defeatCardItem.vue'
import InvalidCardItem from '@/modules/invalidManage/invalidCardItem.vue'
import loading from '@/utils/loading'
import ClueDefeatServices from '@/services/clueDefeatServices'

export default {
  name: 'InvalidDefeat',
  components: { List, DefeatCardItem, InvalidCardItem },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'InvalidDefeat')
    })
  },
  data() {
    return {
      totalCount: 0
    }
  },
  computed: {
    typeInfo() {
      return this.$route.query.type
    }
  },
  watch: {
    dictHash: {
      handler(val) {
        this.onRefresh()
      },
      immediate: true
    }
  },
  activated() {
    this.onRefresh()
  },
  methods: {
    handlerTo({ id,leadId,applyUserRole }) {
      this.$router.push({
        path: '/clue-details',
        query: {
          id: leadId,
        },
      })
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const { type, parentCode='', childCode='', dealerProvinceCode='', dealerCityCode='', dealerIds='' } = this.$route.query
      let params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        type: type === 'defeat' ? 1 : 2,
        queryType: 0
      }
      if (parentCode) {
        params.parentCode = parentCode
      }
      if (childCode) {
        params.childCode = [childCode]
      }
      if (dealerProvinceCode) {
        params.dealerProvinceCode = dealerProvinceCode
      }
      if (dealerCityCode) {
        params.dealerCityCode = dealerCityCode
      }
      if (dealerIds) {
        params.dealerIds = [dealerIds]
      }
      loading.showLoading()
      return ClueDefeatServices.getLeadFailList(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        return res
      })
    },
    // 刷新
    onRefresh() {
      this.$refs?.list?.onRefresh()
    },
  }
}
</script>
  <style lang="less" scoped>
  .btns-bar {
    width: 100%;
    position: absolute;
    justify-content: space-between;
    bottom: 0px;
    display: flex;
    background: #fff;
    padding-left: 16px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  
    /deep/.van-button {
      color: #0D171A !important;
      font-weight: 500;
      // width: 92px;
    }
  }
  
  .totalCount {
    color: @black;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }
  </style>
  